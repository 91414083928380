function openNav() {
    document.getElementById("sideNavigation").style.width = "300px";
    document.getElementById("main").style.marginLeft = "300px";
}

function closeNav() {
    document.getElementById("sideNavigation").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
}

function openSearch() {
    $('#modalBusqueda').modal();
    $('#modalBusqueda #searchsm #texttosearch').focus();
}


$('#aceptaCondiciones').unbind('change');
$('#aceptaCondiciones').change(function () {
    if ($(this).prop('checked')) {
        $('#toCheckout').removeClass('disabled');
        $('#confirmar .alert').hide(100);
    }
    else {
        $('#toCheckout').addClass('disabled');
        $('#confirmar .alert').show(100);
    }
});
$('#toCheckout').unbind('click');
$('#toCheckout').click(function () {
    if (!$('#aceptaCondiciones').prop('checked')) {
        $('#confirmar .alert').show(100);
    }
    else {
        $('#confirmar .alert').hide(100);
    }
});
if (!$.tap.IsMobile() && !$.tap.IsTablet()) {
    $('#texttosearch').attr('placeholder', 'Buscá por titulo, editorial, autor, palabra clave');

}
else if ($.tap.IsMobile()) {
    $('.buscadorMobile ').find('#texttosearch').attr('placeholder', '¿que estás buscando?');
}
$("#modalBusqueda").on('shown.bs.modal', function () {
    console.log('terminó de abrirse modal busqueda...');
    $('#texttosearch').focus();
});

$(function () {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="tooltip"]').tooltip()
})
$('#navigationxs').slinky({
    title: false,
    label: 'Volver'
});
$('#navigationxs a.next').click(function (e) { e.preventDefault(); });


if ($.tap.IsMobile()) {
    $('a[href]').click(function () {
        $('#updateProgress2').show();
        setTimeout(function () {
            $('#updateProgress2').hide();
        }, 5000);
    });
}